import Image from 'next/image';
import Link from 'next/link';

import { Button } from '@/components/ui/button';

import DestinationsCarousel from './DestinationsCarousel';
import { useOriginName } from 'hooks/useQuerySearch';

const DestinationsSection = ({
  title,
  subtitle,
  destinations,
  backgroundImage,
  button,
  preslug,
}: {
  title: string;
  subtitle: string;
  destinations: any[];
  backgroundImage: string;
  button: string;
  preslug?: string;
}) => {
  const [originName] = useOriginName();
  return (
    <div className="flex flex-col relative">
      <div className="relative">
        <Image
          src={backgroundImage}
          alt={title}
          height={853}
          width={1280}
          className="w-full h-96 object-cover"
        />
        <div className="absolute max-md:top-[20%] max-md:w-2/3 w- md:-bottom-1 z-10 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center">
          <h3 className="text-4xl font-prosto">{title}</h3>
          <p className="text-secondary">{subtitle}</p>
        </div>
        <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-primary to-transparent" />
      </div>

      <div className="sm:h-86 flex flex-col items-center bg-cover bg-center bg-primary pt-4">
        <DestinationsCarousel destinations={destinations} preslug={preslug} />

        <Link href={`/search?origin=${originName}`}>
          <Button variant="confirmative" size="lg" className="w-fit my-8">
            {button}
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default DestinationsSection;
