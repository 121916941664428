import { motion } from 'framer-motion';

import CountingNumbers from '@/components/shared/counting-numbers';

export default function RoundedData({
  color,
  value,
  unit,
}: {
  color: string;
  value: number;
  unit: string;
}) {
  return (
    <div className="relative h-full w-full">
      <motion.svg
        className="absolute inset-0 m-auto"
        viewBox="0 0 100 100"
        width={100}
        height={100}
      >
        <motion.circle
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          whileInView={{ pathLength: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5, duration: 2, ease: 'easeOut' }}
          strokeWidth={5}
          strokeDasharray="0 1"
          strokeLinecap="round"
          transform="rotate(-90 50 50)"
          cx="50"
          cy="50"
          r="29"
          fill={
            color === 'primary' ? '#bccae3' : color === 'blue' ? '#DBEAFE' : '#FEE2E2'
          }
          stroke={
            color === 'primary' ? '#0F172A' : color === 'blue' ? '#3B82F6' : '#DC2626'
          }
        />
      </motion.svg>
      <div
        className={`absolute inset-0 mx-auto flex items-center justify-center font-display text-lg text-${color}-500`}
      >
        <CountingNumbers value={value} duration={2500} className="" />
        <p className="text-xs -mr-2">{unit}</p>
      </div>
    </div>
  );
}
