import { getPublicEnvVariable } from 'lib/utilsClientSide';

export const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2023-01-01';

export const dataset = getPublicEnvVariable(
  process.env.NEXT_PUBLIC_SANITY_DATASET,
  'NEXT_PUBLIC_SANITY_DATASET'
);

export const projectId = getPublicEnvVariable(
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  'NEXT_PUBLIC_SANITY_PROJECT_ID'
);
