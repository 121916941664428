import { useState } from 'react';

import { MapPinIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { InterrailTrip } from './interrail-pass/ClientPage';

const AppInterrailTripCard = ({ data }: { data: InterrailTrip }) => {
  const router = useRouter();
  const imageUrl = data.image;
  const imageUrlHovered = data.imageHovered;
  const [isHovered, setIsHovered] = useState(false);

  const goToTripPage = async () => {
    const fullTrip = ['Paris', ...data.cities, 'Paris'];
    const tripString = new URLSearchParams({ trip: fullTrip.join(',') }).toString();
    await router.push(`/interrail-trip-estimation?${tripString}`);
  };
  const t = useTranslations('Passes.popularInterrailRoutes');
  return (
    <div
      onClick={goToTripPage}
      className="inline-flex flex-col items-start gap-3.5 relative group hover:cursor-pointer h-96"
    >
      <div
        className={
          'relative w-60 h-60 rounded-xl overflow-hidden bg-cover bg-center group-hover:transition-transform'
        }
        style={{
          backgroundImage: isHovered ? `url(${imageUrlHovered})` : `url(${imageUrl})`,
          transition: 'background-image 0.5s ease-in-out',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {' '}
        <div className="relative w-60 h-16 top-44 rounded-t-lg bg-gradient-to-b from-transparent to-black opacity-65">
          <div className="absolute w-10 h-3.5 top-11 left-2 font-semibold text-white text-sm leading-4 whitespace-nowrap text-opacity-100">
            {data.duration} {t('days')}
          </div>
        </div>
      </div>
      <div className="relative w-60 h-12">
        <div className="relative h-11 flex flex-col">
          <div className="-top-px left-0 font-bold text-primary text-lg leading-5  hover:underline group-hover:text-slate-500">
            {t(data.title)}
          </div>

          <div className="mt-3 w-full h-4 top-7 -left-0.5 opacity-80 font-normal text-primary text-sm leading-1">
            <MapPinIcon className="h-4 w-4 text-primary  inline-block mr-1" />
            {data.cities.join(', ')}
          </div>
          <p className="mt-8 w-28 h-4 top-7 left-0 opacity-80 font-normal text-primary text-xs leading-none whitespace-nowrap">
            {' '}
            {t('priceAnnouncement')} <span className="font-semibold">{data.price}€</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppInterrailTripCard;
