import { Button } from '@/components/ui/button';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

export const NightTrainMapCard = ({}) => {
  const t = useTranslations('lobby');

  const RedirectionLinkWhileKeepingParams = () => {
    const urlParams = new URLSearchParams();
    const params = Object.fromEntries(urlParams.entries());
    return `/search?nightTrain=true&${new URLSearchParams(params).toString()}`;
  };
  return (
    <Link href={RedirectionLinkWhileKeepingParams()}>
      <div className="relative w-full h-[300px] md:h-[400px] overflow-hidden rounded-3xl group">
        <Image
          alt="night-train-map"
          src="/assets/night-trains-images/nightjet-newgen/couchette4.jpg"
          width={1088}
          height={400}
          quality={100}
          className="group-hover:scale-105 transition-transform group-hover:cursor-pointer w-full h-full object-cover object-top"
        />
        <div className="absolute max-sm:top-6 md:bottom-7 left-6">
          <h2 className="text-3xl md:text-4xl font-semibold text-white shadow-sm pr-3">
            {t('NightTrainMapCard.title')}
          </h2>
        </div>
        <Button
          className="absolute bottom-4 right-4 md:hidden"
          id="button-interrail-banner"
          variant="secondary"
          onClick={() => {
            window.location.href = RedirectionLinkWhileKeepingParams();
          }}
        >
          {t('NightTrainMapCard.cta')}
        </Button>
        <div className="md:block absolute hidden -right-8 bottom-1/2 hover:-translate-x-2 transition-transform translate-y-1/2">
          <div className="bg-secondary opacity-85 md:opacity-100 group-hover:bg-white flex rounded-l-full">
            <span className="text-primary font-semibold text-3xl p-8 md:p-12">
              {t('NightTrainMapCard.cta')}
              <p className="text-slate-500 text-lg font-normal">
                {t('NightTrainMapCard.subCta')}
              </p>
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
