import { ArrowTopRightIcon } from '@sanity/icons';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import Link from 'next/link';

export default function Component({ isDarkMode }) {
  return (
    <section
      className={`container max-w-6xl w-11/12 md:w-2/3 py-12 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}
    >
      <div className="container px-0">
        <div className="grid gap-6 lg:grid-cols-2">
          <div className="space-y-4">
            <div className="flex items-center space-x-5">
              <Image
                src="/assets/they-trust-us/Le_Monde.svg"
                alt="Le Monde"
                width={110}
                height={80}
                className="filter brightness-150 mix-blend-difference"
              />
              <div className="border-l border-gray-300 h-8"></div> {/* Border element */}
              <Link
                href="https://www.lemonde.fr/economie/article/2023/12/17/comment-les-jeunes-reinvestissent-le-voyage-en-train_6206271_3234.html"
                passHref
              >
                <Button
                  variant="link"
                  rel="noopener noreferrer"
                  className={`text-lg font-medium ${isDarkMode ? 'text-orange' : 'text-orange'} flex items-center`}
                >
                  Le Monde
                  <ArrowTopRightIcon className="w-5 h-5 ml-1" />
                </Button>
              </Link>
            </div>
            <blockquote className="text-xl font-serif italic">
              "Un aller Paris-Berlin (de jour) en seconde classe, passant par Bruxelles et
              Cologne (Allemagne) durant un jeudi de janvier 2024, coûte 158 euros sur le
              site de la Deutsche Bahn, contre 269 euros sur SNCF Connect."
            </blockquote>
          </div>
          <div className="space-y-4">
            <div className="flex items-center space-x-5">
              <Image
                src="/assets/they-trust-us/Tom_travel.png"
                alt="Le Figaro"
                width={80}
                height={80}
                className="fill-slate-300"
              />
              <div className="border-l border-gray-300 h-8"></div> {/* Border element */}
              <Link
                href="https://www.tom.travel/2024/05/16/pourquoi-sncf-connect-supprime-des-trajets-internationaux/"
                passHref
              >
                <Button
                  variant="link"
                  rel="noopener noreferrer"
                  className={`text-lg font-medium ${isDarkMode ? 'text-blue-500' : 'text-blue-600'} flex items-center`}
                >
                  Tom Travel
                  <ArrowTopRightIcon className="w-5 h-5 ml-1" />
                </Button>
              </Link>
            </div>
            <blockquote className="text-xl font-serif italic">
              "L’idée est de pouvoir réserver un trajet n’importe où en Europe, comme on
              le ferait avec l’aérien, de façon optimisée et au meilleur prix."
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
}
