'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';
import { useTranslations } from 'next-intl';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGuestAdults, useGuestSeniors, useGuestYouths } from 'hooks/useQuerySearch';
import { formatPrice } from 'utils';
import { getDiffHoursFromDates } from 'utils/datesUtils';
import { Journey, Leg } from 'utils/journey';

import EurailIcon from '../../public/assets/icons/eurail.svg';
import { Badge } from '../ui/badge';
import { Label } from '../ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

import AppJourneyLegs from './AppJourneyLegs';
import { Button } from '../ui/button';
import { goToExternalPageKeepingState } from 'hooks/querySearch';
import { useSearchParams } from 'next/navigation';

interface Props {
  transportMode: string;
  passNeededDays: number;
  defaultUnfolded?: boolean;
}

//return the appropriate pas depending on travel days needed
const passesDurationNeeded = (days: number) => {
  if (days < 5) {
    return 4;
  } else if (days == 5) {
    return 5;
  } else if (days <= 7) {
    return 7;
  } else if (days <= 10) {
    return 10;
  } else if (days <= 15) {
    return 15;
  } else if (days <= 30) {
    return 30;
  } else {
    return 30;
  }
};

const InterrailCard = ({ transportMode, passNeededDays, defaultUnfolded }: Props) => {
  const t = useTranslations('Tickets.interrailCard');

  const searchParams = useSearchParams();

  const [guestsSeniors] = useGuestSeniors();
  const [guestsAdults] = useGuestAdults();
  const [guestsYouths] = useGuestYouths();

  const [isUnfold, setIsUnfold] = useState<boolean>(defaultUnfolded || false);
  const [daysNeeded] = useState<number>(passesDurationNeeded(passNeededDays));
  const [passPrice, setPassPrice] = useState<number>(0);

  //compute the passes price based on /passes formula and days needed
  useEffect(() => {
    setPassPrice(283 * guestsAdults + 255 * guestsSeniors + 212 * guestsYouths);
  }, [guestsAdults, guestsSeniors, guestsYouths]);

  if (transportMode !== 'train') {
    return null;
  }

  return (
    <div
      className={` relative my-2 block h-auto overflow-y-hidden overflow-x-auto bg-white rounded-xl border-[1px] hover:border-slate-300 pt-2 px-2 md:pt-3 md:pb-2 transition-all  w-full md:min-w-96`}
    >
      <div
        className="flex flex-col justify-center"
        onClick={() =>
          setIsUnfold((prev) => {
            return !prev;
          })
        }
      >
        <div
          className={`card relative z-10 flex w-full cursor-pointer flex-row bg-white text-left peer-focus:block ${!isUnfold && ''}`}
        >
          <div className="relative z-10 mr-3 md:mr-[60px] ml-2 flex min-w-fit items-center">
            <Image
              key="interrail"
              src={'/assets/logos/eurail.svg'}
              alt={'train pass interrail'}
              height={60}
              width={60}
              className={''}
              placeholder="blur"
              blurDataURL={'/assets/logos/eurail.svg'}
              style={{
                maxWidth: '100%',
                height: '100%',
              }}
            />
          </div>

          <div className="flex w-full flex-grow flex-row justify-between">
            <div className="items-left flex flex-col justify-between py-2 pr-2">
              <h3 className="font-medium text-lg flex-wrap">{t('4days')}</h3>
              <div className="text-sm flex items-center whitespace-nowrap font-light text-slate-400 gap-0.5">
                <p>{t('title')}</p>
              </div>
            </div>
            <div className="items-right flex flex-col items-end justify-between py-2 px-1 text-right">
              <div className="flex w-max items-center gap-1">
                <p className="text-xs inline-block">{t('from')}</p>
                <h2 className="text-xl font-semibold">{formatPrice(passPrice)}</h2>
              </div>
              <div className="flex w-max text-sm text-slate-400 font-light">
                {t('reservationsNotIncluded')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          isUnfold
            ? 'my-2 h-auto translate-y-0 pt-1 pb-2'
            : 'z-0 h-0 -translate-y-12 overflow-y-hidden bg-white'
        } relative flex flex-col rounded-xl border-2 overflow-x-hidden border-yellow-400 bg-yellow-50 px-3 transition-all duration-300`}
        aria-hidden={!isUnfold}
      >
        <div className="my-3 flex flex-col gap-2">
          <div className="mb-3 flex flex-row justify-between">
            <div className="flex flex-col items-start">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <p className="text-sm font-semibold hover:underline">
                      {t('passPrice', {
                        days: daysNeeded,
                      })}
                      : {formatPrice(passPrice)}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent sideOffset={-10}>
                    <p className="text-sm">
                      {daysNeeded} Non-continous days within a period of 1 month
                    </p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger>
                    <p className="text-sm pt-1 text-slate-400 font-light hover:underline">
                      {t('reservation')}: {'0 to 100€'}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent sideOffset={-4}>
                    <p className="text-sm">
                      Free for regional trains, up to 50€ for couchettes
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>

        <Button
          onClick={goToInterrailTrip(searchParams)}
          id="button-interrail-estimate"
          size="lg"
          className="peer font-bold"
        >
          <EurailIcon className="mr-3 h-5 w-5" />
          <p>{t('estimateBtn')}</p>
        </Button>
      </div>
    </div>
  );
};

const goToInterrailTrip = (searchParams) => {
  return () =>
    goToExternalPageKeepingState(
      searchParams,
      'interrail-trip-estimation',
      `passType=PF&passClass=2&passTravelDays=4D&trip=${searchParams?.get('origin')},${searchParams?.get('destination')}`
    );
};

export default InterrailCard;

export const InterrailJourneySelector = ({
  journeys,
  setSelectedJourney,
  selectedJourney,
  t,
}: {
  journeys: Journey[];
  setSelectedJourney: (journey: Journey) => void;
  selectedJourney: Journey;
  t: any;
}) => {
  return (
    <>
      <Select
        value={selectedJourney.id}
        onValueChange={(value) =>
          setSelectedJourney(journeys.find((journey: Journey) => journey.id === value)!)
        }
      >
        <SelectTrigger className="mb-2">
          <SelectValue placeholder="Select a journey" />
        </SelectTrigger>
        <SelectContent>
          {journeys &&
            journeys.map((journey: Journey, index) => {
              const length = journey.legs
                ?.map((leg: Leg) =>
                  leg.type !== 'PLATFORM_CHANGE' &&
                  leg.type !== 'STATION_CHANGE_PUBLIC_TRANSPORT'
                    ? 0
                    : 1
                )
                .reduce((a, b) => a + b, 0);
              function getConnectionNumberString(connectionCount: number): string {
                switch (connectionCount) {
                  case 0:
                    return ' direct';
                  case 1:
                    return '1 change';
                  default:
                    return connectionCount + ' changes';
                }
              }

              return (
                <SelectItem
                  key={index}
                  value={journey.id}
                  className="flex flex-row w-full gap-x-1"
                  disabled={journey.status == 'INVALID'}
                >
                  <span className="w-full">
                    Duration {getDiffHoursFromDates(journey.arrival, journey.departure)} -{' '}
                  </span>
                  <span>{getConnectionNumberString(length)}</span>
                  {journey.status == 'INVALID' && (
                    <Badge className="ml-3">{journey.status}</Badge>
                  )}{' '}
                  {journey.nighttrain && (
                    <Badge className="ml-2">{t('nightTrain')}</Badge>
                  )}
                  <Label className="ml-3">{formatPrice(journey.amount)}</Label>
                </SelectItem>
              );
            })}
        </SelectContent>
      </Select>

      {selectedJourney && <AppJourneyLegs journeyLegs={selectedJourney.legs} />}
    </>
  );
};
