import Link from 'next/link';
import { useTranslations } from 'next-intl';

import RoundedData from '@/components/atoms/RoundedData';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const CarbonComparative = () => {
  const t = useTranslations('lobby');

  return (
    <Card className="w-full flex flex-col md:flex-row items-center bg-primary-foreground text-center rounded-3xl relative justify-around">
      <CardHeader>
        <CardTitle className="text-xl font-bold">{t('carbon.title')}</CardTitle>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Link
                title="Search Paris-Barcelona"
                href={`/search?origin=Paris&destination=Barcelona&guests=%7B"adults"%3A1%2C"children"%3A0%2C"infants"%3A0%7D`}
              >
                <Badge
                  variant="secondary"
                  className="text-sm md:text-left font-semibold hover:border-primary/50 cursor-pointer"
                >
                  {t('carbon.subtitle')}
                </Badge>
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-sm w-40">{t('carbon.subtitle_tooltip')}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </CardHeader>

      <div className="flex flex-col sm:flex-row items-center md:gap-5 font-medium mb-2 md:mb-0">
        <div className="flex flex-row items-center">
          <CardContent className="w-24 h-24 p-0">
            <RoundedData value={8} unit="kg" color="blue" />
          </CardContent>
          <div className="px-1 md:px-4 p-6">
            <p>{t('carbon.by_train')}</p>
          </div>
        </div>
        <div className="flex flex-row items-center -mt-4 md:mt-0">
          <CardContent className="w-24 h-24 p-0">
            <RoundedData value={427} unit="kg" color="primary" />
          </CardContent>
          <div className="px-1 md:px-4 p-6">
            <p>{t('carbon.by_plane')}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default CarbonComparative;
