import Image from 'next/image';

const imagesWidth = 400;
const imagesHeight = 160;

const TheyTrustUs = () => {
  return (
    <div className="flex flex-row justify-center gap-x-6 md:gap-x-12 md:mx-2 md:pt-3 mix-blend-luminosity">
      <Image
        alt="logo_le_monde"
        src="/assets/they-trust-us/Le_Monde.svg"
        width={imagesWidth}
        height={imagesHeight}
        style={{
          objectFit: 'contain',
        }}
        className="w-1/4 md:w-[14%]"
      />
      <Image
        alt="logo-google"
        src="/assets/they-trust-us/Google_for_Startups_logo.svg"
        width={imagesWidth}
        height={imagesHeight}
        style={{
          objectFit: 'contain',
        }}
        className="hidden md:block w-1/3 md:w-1/4"
      />
      <Image
        alt="logo-stationf"
        src="/assets/they-trust-us/logo_stationf.svg"
        width={imagesWidth}
        height={imagesHeight}
        style={{
          objectFit: 'contain',
        }}
        className="w-1/4 md:w-1/6"
      />
      <Image
        alt="logo-figaro"
        src="/assets/they-trust-us/logo_le_figaro.svg"
        width={imagesWidth}
        height={imagesHeight}
        style={{
          objectFit: 'contain',
        }}
        className="w-1/4 md:w-1/6"
      />
      <Image
        alt="logo-ouestfrance"
        src="/assets/they-trust-us/logo_ouestfrance.svg"
        width={imagesWidth}
        height={imagesHeight}
        style={{
          objectFit: 'contain',
        }}
        className="hidden md:block w-1/4 md:w-[12%]"
      />
    </div>
  );
};
export default TheyTrustUs;
