'use client';

import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { IExploreNearby } from 'typings';

import AppSearchWidget from '../molecules/SearchWidget';

interface HeroProp {
  cover: string;
  exploreNearby: IExploreNearby[];
  title?: string;
}
const AppHero = ({ cover, exploreNearby, title }: HeroProp) => {
  const t = useTranslations('lobby');
  const session = useSession()?.data;
  const isLoggedIn = session ? true : false;

  return (
    <div className="mx-auto mb-12 mt-[5rem] max-w-6xl px-8 md:px-10">
      <div className="relative m-0 pb-8 pt-0 md:h-full md:pb-20 md:pt-16 lg:h-[63vh]">
        <div className="relative mb-4 block aspect-video w-full overflow-hidden bg-cover bg-center bg-no-repeat md:absolute md:top-0 md:mb-0 md:aspect-auto md:h-full">
          <picture className="">
            <Image
              src={cover}
              alt="hero"
              placeholder="blur"
              blurDataURL={cover}
              quality={60}
              priority
              fill
              className="max-w-full rounded-xl md:ml-[25%] md:max-w-[75%]"
              sizes="(min-width: 768px) 50vw, 100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </picture>
        </div>
        <div className="m-0 border-0 p-0">
          <div className="flex-end relative flex h-full w-full flex-col items-stretch">
            <div className="justify-center mb-1 mt-3 flex w-full flex-col md:hidden">
              <h1 className="whitespace-normal text-center text-2xl sm:text-3xl font-extrabold font-prosto md:mt-2.5 md:text-2xl">
                {t.rich('mobileTitle', {
                  color: (chunks) => (
                    <div className="text-orange inline-block items-center transition-colors">
                      <span className="relative group overflow-visible">
                        <Image
                          src="/assets/icons/loco.svg"
                          className="w-8 h-8 mb-2.5 inline-block hover:rotate-6 transition-transform duration-300"
                          height={36}
                          width={36}
                          alt="loco"
                        />
                      </span>{' '}
                      {chunks}
                    </div>
                  ),
                  br: () => <br />,
                })}
              </h1>
            </div>
            <div className="justify-start mb-2 hidden md:flex w-full flex-col">
              <h1 className="whitespace-normal text-left text-3xl font-extrabold md:text-3xl">
                {title}
              </h1>
            </div>
            <div className="-mx-3 z-20 mt-2">
              <AppSearchWidget
                exploreNearby={exploreNearby || []}
                hero
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHero;
