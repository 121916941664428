import { cn } from 'lib/utilsClientSide';

export const Meteors = ({ className }: { number?: number; className?: string }) => {
  const meteorsConstants = [
    [0.74, 0.61, 0.6],
    [0.39, 0.28, 0.71],
    [0.38, 0.26, 0.34],
    [0.98, 0.3, 0.32],
    [0.33, 0.21, 0.95],
    [0.0, 0.03, 0.03],
    [0.71, 0.26, 0.23],
    [0.04, 0.94, 0.29],
    [0.74, 0.0, 0.74],
    [0.34, 0.78, 0.36],
    [0.8, 0.4, 0.03],
    [0.7, 0.49, 0.92],
    [0.25, 0.06, 0.33],
    [0.12, 0.77, 0.4],
    [0.62, 0.32, 0.28],
    [0.68, 0.28, 0.58],
    [0.17, 0.21, 0.68],
    [0.79, 0.45, 0.4],
    [0.48, 0.4, 0.53],
    [0.03, 0.46, 0.95],
  ];
  return (
    <>
      {meteorsConstants.map(([left, delay, duration], idx) => (
        <span
          key={'meteor' + idx}
          className={cn(
            'animate-meteor-effect absolute top-1/2 left-1/2 h-0.5 w-0.5 rounded-[9999px] bg-slate-500 shadow-[0_0_0_1px_#ffffff10] rotate-[215deg]',
            "before:content-[''] before:absolute before:top-1/2 before:transform before:-translate-y-[50%] before:w-[50px] before:h-[1px] before:bg-gradient-to-r before:from-[#64748b] before:to-transparent",
            className
          )}
          style={{
            top: 0,
            left: Math.floor(left * (400 - -400) + -400) + 'px',
            animationDelay: delay * (0.8 - 0.2) + 0.2 + 's',
            animationDuration: Math.floor(duration * (10 - 2) + 2) + 's',
          }}
        ></span>
      ))}
    </>
  );
};
