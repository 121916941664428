import Image from 'next/image';
import { useTranslations } from 'next-intl';

import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const PartnersBanner = () => {
  const t = useTranslations('lobby.partners');

  const partners = [
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/sncf.svg',
      alt: 'SNCF',
      country: 'fr',
    },

    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/cff.svg',
      alt: 'CFF',
      country: 'ch',
    },
    {
      src: 'https://static.trainlinecontent.com/content/vul/logos/uk-eu-carriers/eurostar.svg',
      alt: 'Eurostar',
      country: 'uk',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/obb.svg',
      alt: 'OBB',
      country: 'at',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/db.svg',
      alt: 'Deutsche Bahn',
      country: 'de',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/trenitalia-v2.svg',
      alt: 'Trenitalia',
      hideOnMobile: true,
      country: 'it',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/renfe.svg',
      alt: 'Renfe',
      country: 'es',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/frecciarossa-v2.svg',
      alt: 'Frecciarossa',
      country: 'it',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/tgv_inoui.svg',
      alt: 'TGV InOui',
      country: 'fr',
    },
    {
      src: 'https://static.trainlinecontent.com/content/WEB/images/carriers/v2/color/nightjet.svg',
      alt: 'Nightjet',
      country: 'at',
    },
  ];

  return (
    <Card className="-mx-3 sm:mx-auto rounded-3xl bg-transparent border-none shadow-none pb-5">
      <div className="mb-4 text-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Badge>{t('title')}</Badge>
            </TooltipTrigger>
            <TooltipContent>{t('tooltip')}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 text-center justify-items-center justify-center">
        {' '}
        {partners.map((partner, index) => (
          <div
            key={index}
            className={`flex justify-center items-center p-2 ${partner.hideOnMobile ? 'hidden sm:flex' : ''}`}
            title={partner.alt}
          >
            <div className="h-6 md:h-8 lg:h-8">
              <Image
                height={20}
                width={40}
                className="h-full w-auto object-contain"
                src={partner.src}
                alt={partner.alt}
              />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default PartnersBanner;
