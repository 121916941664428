import Image from 'next/image';
import { useTranslations } from 'next-intl';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

const Founders = () => {
  const t = useTranslations('lobby');

  return (
    <>
      <div className="flex flex-row gap-4 items-start">
        <Card className="w-2/3 sm:w-1/3 flex flex-col items-left text-left relative">
          <CardHeader>
            <CardTitle className="text-xl font-bold">Camille</CardTitle>
            <CardDescription className="text-lg font-semibold">
              CTO & Co-founder
            </CardDescription>
          </CardHeader>
          <CardContent className="text-sm">{t('about.camille')}</CardContent>
        </Card>

        <Image
          src="/assets/founders/Camille.jpg"
          alt="camille_barneaud"
          height={500}
          width={500}
          className="rounded-lg w-1/3 sm:w-52 aspect-square"
        />
      </div>

      <div className="flex flex-row gap-x-4 mt-6 lg:-mt-12 z-0 justify-end items-start">
        <Image
          src="/assets/founders/Marc.jpg"
          alt="marc_sahuguet"
          height={500}
          width={500}
          className="rounded-lg w-1/3 sm:w-52 aspect-square"
        />
        {/* Left Column - Founder 2 */}
        <Card className="w-2/3 sm:w-1/3 items-end text-right relative">
          <CardHeader>
            <CardTitle className="text-xl font-bold">Marc</CardTitle>
            <CardDescription className="text-lg font-semibold">
              CEO & Co-founder
            </CardDescription>
          </CardHeader>
          <CardContent className="text-sm">{t('about.marc')}</CardContent>
        </Card>
      </div>
    </>
  );
};
export default Founders;
