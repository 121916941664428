import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel';

import AppDestinationCard from './AppDestinationCard';

const DestinationsCarousel = ({
  destinations,
  preslug,
}: {
  destinations: any[];
  preslug?: string;
}) => {
  return (
    <div className="max-w-6xl mx-8 my-6 sm:my-auto w-full h-fit">
      {' '}
      <Carousel
        opts={{
          align: 'start',
        }}
      >
        <CarouselContent className="flex sm:gap-x-1 ml-0 md:mx-2">
          {' '}
          {destinations.slice(0, 6).map((data, index) => (
            <CarouselItem
              key={index}
              className="basis-auto lg:basis-1/4 xl:basis-1/5 pl-3"
            >
              <AppDestinationCard data={data} preslug={preslug} />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
export default DestinationsCarousel;
