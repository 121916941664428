import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';

import AppInterrailTripCard from '../AppInterrailTripCard';

import { InterrailTrip } from './ClientPage';

const interrailDestination: InterrailTrip[] = [
  {
    title: 'Discover-Europe',
    duration: 5,
    price: 412,
    image: '/assets/interrail/berlin.jpg',
    imageHovered: '/assets/interrail/amsterdam-trip.jpg',
    cities: ['Amsterdam', 'Berlin', 'Vienna', 'Venice', 'Milan', 'Nice'],
  },
  {
    title: 'Central-Europe',
    duration: 7,
    price: 582,
    image: '/assets/interrail/vienna.jpg',
    imageHovered: '/assets/interrail/vienna-trip.jpg',
    cities: ['Berlin', 'Prague', 'Budapest', 'Vienna', 'Zurich'],
  },
  {
    title: 'Discover-Italy',
    duration: 4,
    price: 375,
    image: '/assets/interrail/italy.jpg',
    imageHovered: '/assets/interrail/italy-trip.jpg',
    cities: ['Venice', 'Rome', 'La Spezia'],
  },
  {
    title: 'Scandinavia-Trip',
    duration: 5,
    price: 445,
    image: '/assets/interrail/scandinavia.webp',
    imageHovered: '/assets/interrail/scandinavia-trip.jpg',
    cities: ['Hamburg', 'Gothenburg', 'Oslo', 'Stockholm', 'Copenhagen'],
  },
  {
    title: 'Southern-Europe',
    duration: 5,
    price: 485,
    image: '/assets/interrail/southernEu.jpg',
    imageHovered: '/assets/interrail/southernEu-trip.jpg',
    cities: ['Nice', 'Barcelona', 'Madrid', 'Lisbon'],
  },

  {
    title: 'North-Sea-Countries-Trip',
    duration: 5,
    price: 471,
    image: '/assets/interrail/brussels.jpg',
    imageHovered: '/assets/interrail/north-sea-trip.jpg',
    cities: ['Colmar, Luxembourg, Brussels, Amsterdam, London, Edinburgh'],
  },

  {
    title: 'Spain-Trip',
    duration: 6,
    price: 510,
    image: '/assets/interrail/sevilla.jpg',
    imageHovered: '/assets/interrail/spain-trip.jpg',
    cities: ['Latour-de-carol', 'Barcelona', 'Valencia', 'Alicante', 'Malaga', 'Seville'],
  },

  {
    title: 'Eastern-Europe',
    duration: 7,
    price: 459,
    image: '/assets/interrail/bratislava.jpg',
    imageHovered: '/assets/interrail/eastern-europe-trip.jpg',
    cities: ['Prague', 'Kraków', 'Bratislava', 'Budapest', 'Beograd', 'Ljubljana'],
  },

  {
    title: 'Italy-Tour',
    duration: 5,
    price: 589,
    image: '/assets/interrail/como.jpg',
    imageHovered: '/assets/interrail/italy-tour-trip.jpg',
    cities: ['Milan', 'Como', 'Genova', 'Pisa', 'Firenze', 'Venice', 'Verona', 'Milan'],
  },
];

const InterrailCarousel = () => {
  return (
    <Carousel
      opts={{
        align: 'start',
      }}
    >
      <CarouselContent className="flex -ml-4">
        {' '}
        {interrailDestination.slice(0, 10).map((data, index) => (
          <CarouselItem key={index} className="basis-auto xl:basis-1/4">
            <AppInterrailTripCard data={data} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <div className="max-xl:hidden">
        <CarouselPrevious />
        <CarouselNext />
      </div>
    </Carousel>
  );
};

export default InterrailCarousel;
