import Link from 'next/link';

import { urlForImage } from 'page/sanity.image';

const AppDestinationCard = ({
  data,
  preslug,
}: {
  data: {
    coverImage: any;
    slug: { current: string };
    title: string;
    price: number;
    duration: number;
  };
  preslug?: string;
}) => {
  let imageUrl = '';
  if (typeof data.coverImage === 'string') {
    imageUrl = data.coverImage;
  } else {
    imageUrl = urlForImage(data.coverImage?.asset?._ref).height(300).width(600).url();
  }
  return (
    <Link
      href={`${preslug || ''}${data.slug.current}`}
      className="inline-flex flex-col items-start gap-3.5 relative group hover:cursor-pointer hover:scale-95"
    >
      <div
        className={`relative w-44 h-44 rounded-xl overflow-hidden bg-cover bg-center  group-hover:transition-transform`}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {' '}
        <div className="relative w-44 h-16 top-28 rounded-t-lg bg-gradient-to-b from-transparent to-black opacity-65">
          <div className="absolute w-10 h-3.5 top-11 left-3.5 font-semibold text-white text-xs leading-4 whitespace-nowrap">
            {data.duration && `${data.duration} jours`}
          </div>
        </div>
      </div>

      <div className="relative w-44 h-12">
        <div className="relative h-11">
          <div className="absolute w-40 -top-px left-0 font-bold text-white text-lg leading-6 whitespace-nowrap hover:underline group-hover:text-orange/80 group-hover:transition-colors">
            {data.title}
          </div>
          <p className="absolute w-28 h-4 top-7 left-0 opacity-80 font-normal text-white text-xs leading-none whitespace-nowrap">
            {' '}
            À partir de <span className="font-semibold">{data.price}€</span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default AppDestinationCard;
